import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Observer } from "gsap/Observer";

if (document.readyState !== "loading") {
  console.log("document is already ready, just execute code here");
  setup();
} else {
  document.addEventListener("DOMContentLoaded", function () {
    console.log("document was not ready, place code here");
    setup();
  });
}

function setup() {
  gsap.registerPlugin(ScrollTrigger, Observer);

  let video = document.getElementById("scroll_video");
  const loadingSpinner = document.querySelector(".loadingSpinner");

  function setupScrollTrigger() {
    let tl = gsap.timeline({
      defaults: { duration: 1 },
      scrollTrigger: {
        trigger: "#scroll_lock_section video",
        start: "center center",
        end: "+=5000",
        pin: true,
        markers: true,
        scrub: 1.3,
      },
    });

    tl.fromTo(video, { currentTime: 0 }, { currentTime: video.duration || 1 });
  }

  async function loadVideo() {
    let url = video.src;
    video.src = null;
    if (!url) {
      console.error("No video URL found.");
      return;
    }
    console.log(url);
    try {
      console.log("Fetching Video...");
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error(`Response status: ${response.status}`);
      }

      console.log("Found Video source, fetching and creating blob url...");
      const videoBlob = await response.blob();
      const objectURL = URL.createObjectURL(videoBlob);
      video.src = objectURL;
      console.log(video.src);
      video.addEventListener("loadedmetadata", () => {
        loadingSpinner.classList.add("isLoaded");
        console.log("Video ready, duration: " + video.duration);

        setupScrollTrigger();
      });

      console.log("Video loaded, waiting for metadata...");
    } catch (error) {
      console.error("Error loading video:", error.message);
    }
  }

  loadVideo();
  //   setupScrollTrigger();
}
